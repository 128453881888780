
  import Vue from 'vue';
  import * as api from '@/api';

  export default Vue.extend({
    name: 'brand',
    data() {
      return {
        selection: [],
        brand: {},
        currentPage: 0,
        total: 0,
        pageSize: 10,
        pageSizes: [10, 20],
        sorting: false,
        searchVal: '',
        tableData: [] as api.Brand[],
      };
    },
    mounted() {
      this.getBrand();
    },
    methods: {
      handleSizeChange(val) {
        this.pageSize = val;
        this.getBrand();
      },
      async getBrand() { // 获取列表
        const opt = {
          name: this.searchVal,
          SkipCount: this.currentPage === 0 ? 0 : (this.currentPage - 1) * this.pageSize,
          MaxResultCount: this.pageSize,
        };
        if (this.sorting) (opt as any).Sorting = 'pinyinFirstLetter ASC';
        const res = await api.getBrandList(opt);
        if (!api.error(res)) {
          this.tableData = res.data.items;
          this.total = Number(res.data.totalCount);
        }
      },
      isDisabled(row) {
        return row.isBuiltin ? 0 : 1;
      },
      toProduct(row) {
        if (row.productCount > 0) {
          this.$router.push({
            name: 'ProductList',
            query: {
              brandId: row.id,
            },
          });
        }
      },
      handleSorting(val) {
        this.sorting = val;
        this.getBrand();
      },
      search() {
        this.getBrand();
      },
      reset() {
        this.searchVal = '';
        this.getBrand();
      },
      handleCommand(val) {
        if (this.selection.length === 0) {
          this.$message({
            type: 'warning',
            message: '未选中任何品牌',
          });
          return;
        }
        if (val === 0 || val === 1) {
          this.isManufacturer(true, val === 0);
        } else if (val === 2 || val === 3) {
          this.isShow(true, val === 2);
        } else {
          const deletable = this.selection.some((e) => (e as any).deletable === false);
          if (deletable) {
            this.$message({
              type: 'warning',
              message: '部分产品不可删除',
            });
            return;
          }
          this.$confirm('是否确认删除这些品牌?品牌删除后,将无法恢复', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.del(this.selection);
          });
        }
      },
      async isManufacturer(batch, val) { // 修改是否制造商
        let opt = batch ? this.selection : [val];
        opt = opt.map((e) => ({
          brandId: Number((e as any).id),
          isManufacturer: batch ? val : e.isManufacturer,
        }));
        const res = await api.putIsManufacturer(opt);
        if (!api.error(res)) {
          batch && this.getBrand();
        } else {
          val.isManufacturer = !val.isManufacturer; // 单个的话再回到原先状态
          this.$message.error(res.error.message);
        }
      },
      async isShow(batch, val) { // 修改是否是前台显示
        let opt = batch ? this.selection : [val];
        opt = opt.map((e) => ({
          brandId: Number((e as any).id),
          isShow: batch ? val : e.isShow,
        }));
        const res = await api.putIsShow(opt);
        if (!api.error(res)) {
          batch && this.getBrand();
        } else {
          val.isShow = !val.isShow; // 单个的话再回到原先状态
          this.$message.error(res.error.message);
        }
      },
      handleSelectionChange(val) {
        this.selection = val;
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getBrand();
      },
      open(val) {
        this.$store.commit('breadcrumbList', [
          {
            name: '品牌管理',
            path: '/product/brand',
          },
          {
            name: val.code ? '编辑品牌' : '添加品牌',
            path: val.code ? 'edit' : 'add',
          },
        ]);
        this.$store.commit('brandInfo', val);
        const name = val.name ? 'edit' : 'add';
        this.$router.push({ path: `/product/brand/${name}` });
      },
      async del(val) {
        const res = await api.deleteBrand(val.map((e) => e.id));
        if (!api.error(res)) {
          this.$message({
            type: 'success',
            message: '删除成功',
          });
          this.getBrand();
        } else {
          this.$message.error('删除失败');
        }
      },
    },
    created() {
      this.$store.commit('breadcrumbList', [
        {
          name: '品牌管理',
          path: '/brand',
        },
      ]);
    },
  });

